import {format, isThisMonth, isThisYear, isToday, isWithinInterval, isYesterday, parseISO, startOfDay} from "date-fns";
import { Chat } from "../types/Types";


export function sleep(ms: number | undefined) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  
  export function convertFloat32ToS16PCM(float32Array: Float32Array) {
    const int16Array = new Int16Array(float32Array.length);
  
    for (let i = 0; i < float32Array.length; i++) {
      const clampedValue = Math.max(-1, Math.min(1, float32Array[i]));
      int16Array[i] = clampedValue < 0 ? clampedValue * 32768 : clampedValue * 32767;
    }
    return int16Array;
  }

export const categorizeAndGroupData = (data: Chat[]) => {
    const now = new Date();
    const startOfYesterday = startOfDay(now.setDate(now.getDate() - 1));
    const startOfLastWeek = startOfDay(now.setDate(now.getDate() - 7));

    const groups: { [key: string]: Chat[] } = {};

    data.forEach(item => {
        const date = parseISO(item.createdAt);
        let category;
        if (isToday(date)) {
            category = 'Today';
        } else if (isYesterday(date)) {
            category = 'Yesterday';
        } else if (isWithinInterval(date, { start: startOfLastWeek, end: startOfYesterday })) {
            category = 'Previous 7 days';
        } else if (isThisMonth(date)) {
            category = 'Previous 30 days';
        } else {
            category = isThisYear(date) ? format(date, 'MMMM') : format(date, 'MMMM yyyy');
        }
        if (!groups[category]) {
            groups[category] = [];
        }
        groups[category].push(item);
    });

    return groups;
};

export function separateObjects(input: string): any[] {
    const regex = /}{/g;
    const jsonStrings = input.split(regex);

    return jsonStrings.map((str, index) => {
        let jsonString = str;
        if (index > 0) {
            jsonString = `{${jsonString}`;
        }
        if (index < jsonStrings.length - 1) {
            jsonString = `${jsonString}}`;
        }

        try {
            return JSON.parse(jsonString);
        } catch (error) {
            console.error(`Invalid JSON format for object ${index + 1}:`, error);
            return null; 
        }
    }).filter(Boolean);
}

export function extractLastCompleteObject(jsonString: string) {
    // Find the start of the metadata array
    const metadataStart = jsonString.indexOf('"metadata": [');
    if (metadataStart === -1) return null;

    // Find all complete objects
    const objects = [];
    let bracketCount = 0;
    let objectStart = metadataStart + 12; // Length of '"metadata": ['

    for (let i = objectStart; i < jsonString.length; i++) {
        if (jsonString[i] === '{') {
            if (bracketCount === 0) objectStart = i;
            bracketCount++;
        } else if (jsonString[i] === '}') {
            bracketCount--;
            if (bracketCount === 0) {
                objects.push(JSON.parse(jsonString.substring(objectStart, i + 1)));
            }
        }
    }
    // console.log('objects', objects);
    // Return the last complete object, or null if no complete objects found
    return objects;
}

export async function parseMetadata(metadata: any[]) {
    const unicornSourcesPromises = metadata.map(async (item: { featured_media: { toString: () => string | string[]; }; link: any; title: any; date: any; }) => {
        let imageResponse;
        const isImage = ['png', 'jpg', 'jpeg'].some(value => item.featured_media.toString().includes(value));
        try {
        //     if (item._links?.["wp:featuredmedia"]?.[0].href) {
        //         if (['png', 'jpg', 'jpeg'].some(value => item._links?.["wp:featuredmedia"]?.[0]?.href?.includes(value))) {
        //             imageResponse = { guid: { rendered: item._links?.["wp:featuredmedia"]?.[0]?.href } };
        //         } else {
        //             console.log("href", item._links?.["wp:featuredmedia"]?.[0].href);
        //             const response1 = await fetch(item._links?.["wp:featuredmedia"]?.[0].href);
        //             // console.log('response1', response1);
        //             imageResponse = await response1.json();
        //         }
        //     } else if (item._links?.["wp:featuredmedia"]) {
            //     }

            if (!isImage) {
                const response1 = await fetch(`https://fabricegrinda.com/wp-json/wp/v2/media/${item.featured_media}`);
                // console.log('response1', response1);
                imageResponse = await response1.json();
            }

        } catch (e) {
            console.log(e);
        }
        // console.log('imageResponse', imageResponse);
        return {
            img: isImage ? item.featured_media : imageResponse?.guid?.rendered, link: item.link, title: item.title, date: item.date
        };
    });


    const unicornSourcesResponse = await Promise.all(unicornSourcesPromises);
    return unicornSourcesResponse[0];
    // console.log('unicornSources', unicornSources);
}