/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import pause from '../assets/images/pause.svg';
import StreamingAvatar, {
    AvatarQuality,
    StreamingEvents,
    TaskMode,
    TaskType
} from "@heygen/streaming-avatar";
import CryptoJS from 'crypto-js';
import { toast } from "react-toastify";

// Define types for component props
interface AvatarComponentProps {
    index: number;
    contentLoading: boolean;
    isLastIndex: boolean;
    videoMode: boolean;
    message: string;
    loadingMessage: string;
    clearLoadingMessage: () => void;
    updateUserMessage: (message: string) => void;
}

// Enum for Avatar Status
enum AvatarStatus {
    NOT_INITIALIZED = 'not_initialized',
    INITIALIZING = 'initializing',
    INITIALIZED = 'initialized'
}

export const AvatarComponent: React.FC<AvatarComponentProps> = ({
    index, contentLoading, isLastIndex, videoMode, message, loadingMessage, clearLoadingMessage, updateUserMessage
}) => {
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [initialized, setInitialized] = useState<boolean>(false);
    const mediaStream = useRef<HTMLVideoElement>(null);
    const avatar = useRef<StreamingAvatar | null>(null);
    const [avatarId] = useState<string>("Wayne_20240711");
    const [voiceId] = useState<string>("867814af4d834e519793267b895aaeb2");
    const [data, setData] = useState<any>();
    const [avatarStatus, setAvatarStatus] = useState<AvatarStatus>(AvatarStatus.NOT_INITIALIZED);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const avatarContainerRef = useRef<HTMLDivElement>(null);

    // Mutable flag for first talk
    let firstTalk = useRef(true);

    useEffect(() => {
        if (videoMode && avatarStatus === AvatarStatus.INITIALIZED) {
            if (contentLoading) {
                if (isLastIndex) {
                    if (loadingMessage.split(" ").length >= 15) {
                        console.log('loadingMessage', loadingMessage);
                        console.log('started speaking when loading');
                        speak(loadingMessage);
                    }
                } else {
                    console.log('interrupted speaking when loading');
                }
            }
        }
    }, [contentLoading, isLastIndex, avatarStatus, videoMode, loadingMessage]);

    useEffect(() => {
        if (!contentLoading && !isPlaying && loadingMessage.length > 0) {
            console.log('loadingMessage', loadingMessage);
            speak(loadingMessage);
            firstTalk.current = true;
        }
    }, [isPlaying, loadingMessage, contentLoading]);

    function decryptToken(encryptedToken: string): string | null {
        try {
            
            const encryptedData = CryptoJS.enc.Base64.parse(encryptedToken);
        // Separate IV and ciphertext
        const iv = CryptoJS.lib.WordArray.create(encryptedData.words.slice(0, 4), 16);
        const ciphertext = CryptoJS.lib.WordArray.create(encryptedData.words.slice(4));
        const decryptKey = process.env.REACT_APP_MY_DECRYPTION_KEY!.toString();
        // console.log("decryptKey", decryptKey);
        const key = CryptoJS.enc.Utf8.parse(decryptKey);  // Ensure this key matches AES_KEY from backend
 
        const decrypted = CryptoJS.AES.decrypt(
            { ciphertext: ciphertext } as CryptoJS.lib.CipherParams,
            key,
            { iv: iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC }
        );

        const decryptToken = decrypted.toString(CryptoJS.enc.Utf8);
        // console.log("decryptToken", decryptToken);
        return decryptToken;
        } catch (error) {
            console.error("Decryption failed", error);
            return null;
        }
    }

    function speak(message: string) {
        if (!isPlaying && message.length > 0) {
            mediaStream.current?.play();
            console.log("Avatar started talking");
            avatar.current?.speak({
                text: message,
                task_type: TaskType.REPEAT
            }).catch(() => {
                setAvatarStatus(AvatarStatus.NOT_INITIALIZED);
                init();
            });
            clearLoadingMessage();
        }
    }

    async function init() {
        setAvatarStatus(AvatarStatus.INITIALIZING);

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/getAccessToken`);
            // await fetch(
            //     "https://api.heygen.com/v1/streaming.create_token",
            //     {
            //       method: "POST",
            //       headers: {
            //         "x-api-key": "ZmM5ODY3YmFiNDY4NDk5MmI2ZDgwNzMyZTQ4ZDI4OTctMTczMDg4ODM4Ng==",
            //       },
            //     },
            //   )
            const result = await response.json();
            const token = decryptToken(result.data.token);
            // const token = result.data.token

            if (!token) {
                throw new Error('Token decryption failed');
            }

            avatar.current = new StreamingAvatar({ token });

            avatar.current.on(StreamingEvents.AVATAR_START_TALKING, () => {
                if (firstTalk.current) {
                    interrupt();
                    firstTalk.current = false;
                }
                console.log('started talking');
                setIsPlaying(true);
            });

            avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, () => {
                console.log('stopped talking');
                setIsPlaying(false);
            });

            avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
                setIsPlaying(false);
                setAvatarStatus(AvatarStatus.NOT_INITIALIZED);
            });

            avatar.current.on(StreamingEvents.STREAM_READY, (e: any) => {
                setStream(e.detail);
                // console.log("stream ready", e.detail);
            });

            avatar.current.on(StreamingEvents.USER_TALKING_MESSAGE, async (e) => {
                console.log("user message", e);
                updateUserMessage(e.detail.message);
                mediaStream.current?.pause();
            });

            setInitialized(true);

            const res = await avatar.current?.createStartAvatar({
                quality: AvatarQuality.Low,
                avatarName: avatarId,
                voice: {
                    voiceId: voiceId
                }
            });

            if (res) setData(res);
            await avatar.current?.startVoiceChat();
        } catch (error) {
            toast.error("Something went wrong. Please try again later.");
            console.error('Error starting avatar session:', error);
        }
    }

    useEffect(() => {
        if (stream && mediaStream.current) {
            mediaStream.current.srcObject = stream;
            console.log('stream', mediaStream.current?.srcObject);
            mediaStream.current.onloadedmetadata = () => {
                if (mediaStream.current) {
                    setAvatarStatus(AvatarStatus.INITIALIZED);
                    mediaStream.current.play().then(() => {
                        if (!contentLoading && videoMode) {
                            console.log('speak init', message);
                            speak(message);
                        }
                    }).catch((e) => {
                        console.log('Stream play error', e);
                    });
                }
            };
        }
    }, [mediaStream, stream]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                console.log('isVisible', entry.isIntersecting);
                setIsVisible(entry.isIntersecting);
                // firstTalk.current = entry.isIntersecting;
            },
            {
                threshold: 1.0
            }
        );

        if (videoMode) {
            init();
            avatarContainerRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });

            if (avatarContainerRef.current) {
                observer.observe(avatarContainerRef.current);
            }
        } else {
            stop();
            if (avatarContainerRef.current) {
                observer.unobserve(avatarContainerRef.current);
            }
        }

        return () => {
            console.log('stopped streaming');
            stop();
        }
    }, [videoMode]);

    async function stop() {
        avatar.current?.closeVoiceChat();
        if (avatarStatus === AvatarStatus.INITIALIZED){ await avatar.current?.stopAvatar();}
    }

    async function interrupt() {
        try {
            if (isPlaying) avatar.current?.interrupt();
        } catch (e) {
            mediaStream.current?.pause();
        }
    }

    const handlePlayPause = () => {
        if (isPlaying) {
            interrupt();
        } else {
            speak(message);
        }
    };

    return (
        <div
            key={index}
            ref={avatarContainerRef}
            id={'avatar-container'}
            className={'flex flex-col relative lg:pl-4'}
        >
            {(avatarStatus === AvatarStatus.INITIALIZING) && (
                <div className={'animate-pulse h-[480px] w-full absolute top-0 start:0 lg:start-4'}>
                    <div className="w-full h-full bg-slate-200 rounded-md"></div>
                </div>
            )}
            <video
                ref={mediaStream}
                autoPlay
                playsInline
                style={{
                    width: "100%",
                    height: "480px",
                    objectFit: "fill",
                }}
                onAnimationStart={() => {
                    setAvatarStatus(AvatarStatus.INITIALIZED);
                }}
            >
                <track kind="captions" />
            </video>
            <div
                className={'hidden hover:flex absolute top-0 start:0 lg:start-4'}
            >
                {initialized && isPlaying && (
                    <img
                        className={"m-auto"}
                        alt="pause"
                        src={pause}
                        onClick={handlePlayPause}
                        width={80}
                        height={80}
                    />
                )}
            </div>
        </div>
    );
};